.DayPicker {
    position: absolute;
}

.DayPicker.DayPicker__horizontal {
    right: 20px;
    z-index: 10;
}

.separator {
    line-height: 2rem !important;
    padding-right: 5px;
    padding-left: 5px;
}

.range-wrapper {
    display: flex;
    padding: 0 10px;
}

.range-wrapper > div {
    text-align: center;
    line-height: 12px;
}

.range-wrapper small {
    line-height: 12px;
}

.selected-month {
    color: dodgerblue;
}

.narrow-button {
    width: 1em;
}

.narrow-button i {
    left: -3px;
    position: relative;
}
